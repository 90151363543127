<template>
	<div id="main-wrapper" class="inner-pages why-page safety-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>Safety of Funds</h2>
				<p>Integrity, Trust and Full Reliability</p>
			</div>
		</div>
		<div class="section why-bel">
			<div class="wrapper">
				<div class="content-sec wow fadeIn">
					<div class="title-holder t-center ">
						<h3>Funds Safety and Security</h3>
					</div>
					<p  class="t-center">The traders should focus all their time and energy on building effective strategies, rather than worrying about their funds’ safety. <br>
					We are here to protect your funds because our foundation lays on your trust. We want you to invest smartly and trade safely. </p>
				</div>
			</div>
		</div>
		<div class="section safe-sec">
			<div class="wrapper2">
				<div class="title-holder t-white t-center">
					<h3>REGULATED & GUARDED</h3>
					<p>In Compliance with strict regulatory framework of EU’s Hellenic Capital Market Commission (HCMC)</p>
				</div>
				<div class="safe-content clearfix wow fadeIn">
					<div class="left-col col f-left wow fadeInLeft" data-wow-delay="0.6s">
						<div class="content">
							<div class="text-holder">
								<h3>RESPONSIBLE FIDUCIARY</h3>
								<p>Client money is safely held in Secure Bank Accounts, with trustworthy banks.</p>
							</div>
							<div class="img-holder">
								<img src="assets/images/safe1.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
						</div>
						<div class="content">
							<div class="text-holder">
								<h3>DEDICATED AUDITS AND<br> COMPLIANCE REVIEWS</h3>
								<p>Internal and External audits, Dedicated Compliance function to constantly review the application of client asset procedures.</p>
							</div>
							<div class="img-holder">
								<img src="assets/images/safe3.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
						</div>
						<div class="content">
							<div class="text-holder">
								<h3>NEGATIVE BALANCE PROTECTION</h3>
								<p>The clients cannot lose more than their account balance. If at all their balance goes negative, we reset it to zero. </p>
							</div>
							<div class="img-holder">
								<img src="assets/images/safe4.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
						</div>
					</div>
					<div class="right-col col f-right wow fadeInRight" data-wow-delay="0.6s">
						<div class="content">
							<div class="img-holder">
								<img src="assets/images/safe2.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
							<div class="text-holder">
								<h3>SEGREGATED FUNDS AND RECORDS</h3>
								<p>No use of clients’ funds for own expenses.
									Clear distinguish between clients fund’s & records and ours.</p>
							</div>
						</div>
						<div class="content">
							<div class="img-holder">
								<img src="assets/images/safe4.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
							<div class="text-holder">
								<h3>ROBUST IT POLICIES</h3>
								<p>Secure web sockets on the website, Cloudflare technology, and Two Factor Authentication (2FA) available for all client accounts. </p>
							</div>
						</div>
						<div class="content">
							<div class="img-holder">
								<img src="assets/images/safe6.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
							<div class="text-holder">
								<h3>INVESTORS COMPENSATION FUND</h3>
								<p>AAAFx investors are protected up to €30,000 per person by the Greek Guarantee Fund according under the European Directive 93/22/EEC.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section comm-sec">
			<div class="wrapper clearfix">
				<div class="content f-right wow fadeInRight">
					<div class="title-holder">
						<h5>We are always committed towards ensuring your security. </h5>
						<br>
						<p>But your Awareness and Alertness is equally important:</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm1.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>Never</strong> click on unidentified links in emails.</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm2.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>Never</strong> use unsecured wireless networks or any public device for accessing financial accounts.</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm3.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>Never</strong> reply to emails or SMSs seeking personal information or OTPs.</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm4.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>Never</strong> enter your personal or financial information on unsecured websites.</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm5.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>Never</strong> leave your device unattended while you are logged in to secured personal or financial accounts.</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm6.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>Never</strong> operate a financial account without 2FA.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>